import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import MetaTags from 'react-meta-tags';
import Slide from 'react-reveal/Slide';

import helpers from "../utils/iHelpers.js";
import PageLoader from '../components/PageLoader';
import PageError from '../components/PageError';
import API from "../utils/API";

import PageTitle from '../components/PageTitle';
import SkillSet from '../components/SkillSet';
import List from '../components/List';

const PAGE_API = process.env.REACT_APP_PAGE_SKILLS;

class Skills extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      title: "Skills",
      field: [],
      metatag: [],
      isLoading: false,
      error: null,
    };
  }

  componentDidMount() {
    helpers.initGA();
    this.setState({ isLoading: true });
    API.get(PAGE_API)    
      .then(res => {
          const data = res.data;
          this.setState({ field: data.metas, metatag: data.meta_head, isLoading: false });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {

    const { isLoading, metatag, error, field } = this.state;

    if (error) {
      return <PageError message={error.message}/>; 
    }

    if (isLoading) {
      return <PageLoader />;
    }
    if(!field.expert) {
      
      return <PageLoader />;
    }

    return(
      
      <section className="resume-section p-3 p-lg-5 d-flex align-items-center" id="skills">
        <MetaTags>
          <title>{metatag.og_title}</title>
          <meta name="description" content={metatag.og_description} />
          <meta property="og:title" content={metatag.og_title} />
          <meta property="og:description" content={metatag.og_description} />
          <meta property="og:image" content={metatag.og_image} />
          <meta property="og:url" content={process.env.REACT_APP_URL}skills />
        </MetaTags>
        <div className="w-100">
          <PageTitle title={this.state.title} />
            <SkillSet className="mb-5" data={ field.skills } />     
          <Slide left>
          </Slide>     
          <div className="clearfix mt-5"></div>
          <Slide right>
            <List className="mt-5" data={ field.expert } />
          </Slide>     
        </div>
      </section>
      );
  }
}
export default Skills