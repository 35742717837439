import React from 'react';
import helpers from "../utils/iHelpers.js";
import ReactHtmlParser from 'react-html-parser';
import MetaTags from 'react-meta-tags';
import PageTitle from '../components/PageTitle';
//import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import PageLoader from '../components/PageLoader';
import PageError from '../components/PageError';
import API from "../utils/API";

const PAGE_API = process.env.REACT_APP_PAGE_EDUCATION;


class Education extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: "Education",
      records: [],
      field: [],
      metatag: [],      
      isLoading: false,
      error: null,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    API.get(PAGE_API)    
      .then(res => {
          const data = res.data;
          this.setState({ records: data.metas.education, metatag: data.meta_head, isLoading: false });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const { records, metatag, isLoading, error } = this.state;

    if (error) {    
      return <PageError message={error.message}/>;      
    }

    if (isLoading) {
      return <PageLoader />;      
    }

    return (
      <section className="resume-section p-3 p-lg-5 d-flex align-items-center" id="education">
      <MetaTags>
          <title>{metatag.og_title}</title>
          <meta name="description" content={metatag.og_description} />
          <meta property="og:title" content={metatag.og_title} />
          <meta property="og:description" content={metatag.og_description} />
          <meta property="og:image" content={metatag.og_image} />
        </MetaTags>
        <div className="w-100">
          <PageTitle title={this.state.title} />
          {records.map((record, index) =>
            <Slide cascade right={(index % 2 === 0) ? 0 : 1} left={(index % 2 === 0) ? 1 : 0}>
              <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5 { (index % 2 == 0) ? 'sleft' : 'sright' }">
                <div className="resume-content">
                  <h3 className="mb-0">{record.school}</h3>
                  <div className="subheading mb-3">{record.course}</div>
                  <div>{record.major}</div>
                  <div>{record.awards}</div>
                </div>
                <div className="resume-date text-md-right">
                  <span className="text-primary">{record.date_start} - {record.date_end}</span>
                </div>
              </div>
            </Slide>
          )}
        </div>
      </section>      
    );
  }

} //render


export default Education
