import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import MetaTags from 'react-meta-tags';
import PageTitle from '../components/PageTitle';
import Slide from 'react-reveal/Slide';

import helpers from "../utils/iHelpers.js";
import PageLoader from '../components/PageLoader';
import PageError from '../components/PageError';

const BASE_URL = helpers.baseURL();
const API_URL = BASE_URL+'wp-json/wp/v2/pages/26';

class Experience extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: "Experience",
      records: [],
      field: [],
      metatag: [],        
      isLoading: false,
      error: null,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    fetch(API_URL)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => this.setState({ records: data.metas.experience, metatag: data.meta_head, isLoading: false }))
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const { records, metatag, isLoading, error } = this.state;
    if (error) {    
      return <PageError message={error.message}/>;   
    }
    
    if (isLoading) {
      return <PageLoader />;      
    }
    
    /*if (!records) {return null;}*/

    return (

      <section className="resume-section p-3 p-lg-5 d-flex align-items-center">
        <MetaTags>
          <title>{metatag.og_title}</title>
          <meta name="description" content={metatag.og_description} />
          <meta property="og:title" content={metatag.og_title} />
          <meta property="og:description" content={metatag.og_description} />
          <meta property="og:image" content={metatag.og_image} />
        </MetaTags>
        <div className="w-100">
          <PageTitle title={this.state.title} />
          <div className="records-list">
            {records.map((record, index) =>
              <Slide cascade right={(index % 2 === 0) ? 0 : 1} left={(index % 2 === 0) ? 1 : 0}>
                <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5 record-row">
                  <div className="resume-content">
                    <h3 className="mb-0">{record.position}</h3>
                    <div className="subheading mb-3">{record.company}</div>
                    {ReactHtmlParser(record.description)}
                    {ReactHtmlParser(record.role)}
                  </div>
                  <div className="resume-date text-md-right">
                    <span className="text-primary">{record.date_start} - {record.date_end ? record.date_end : 'Present'}</span>
                  </div>
                </div>
              </Slide>
            )}
          </div>
        </div>
      </section>
    );

  }

}

export default Experience;
