import React from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import About from "../pages/about";
import Experience from "../pages/experience";
import Portfolio from "../pages/portfolio";
import Education from "../pages/education";
import Skills from "../pages/skills";
import Interests from "../pages/interests";
import Others from "../pages/others";
import Services from "../pages/services";
import Contact from "../pages/contact";
import Notfound from "../pages/notfound";

import Blog from "../pages/blog";
import Article from "../components/Article";

function Routers() {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={About} />
        <Route path="/experience" component={Experience} />
        <Route path="/portfolio" component={Portfolio} />
        <Route path="/education" component={Education} />
        <Route path="/interests" component={Interests} />
        <Route path="/skills" component={Skills} />
        <Route path="/others" component={Others} />
        <Route path="/services" component={Services} />
        <Route path="/contact" component={Contact} />

        <Route path="/articles/:slug" component={Blog} />
        <Route path="/articles" component={Blog} />
        <Route path="/article/:slug" component={Article} />

        <Route component={Notfound} />
      </Switch>
    </div>
  );
}

export default Routers;
