import React, { Component } from 'react';
import { BrowserRouter as Link, NavLink  } from "react-router-dom";

class Header extends Component {
  render() {
    return (
      <nav
        className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top"
        id="sideNav"
      >
        <a className="navbar-brand" href="/">
          <span className="d-block d-lg-none">
            <img
              src={process.env.PUBLIC_URL + "/img/hellofritz.png"}
              className="img-fluid img-profile mx-auto mb-2 mobile-logo"
              alt={process.env.REACT_APP_NAME}
            />            
          </span>
          <span className="d-none d-lg-block">
            <Link to="/">
              <div className="glow-on-hover">
                <img
                  src={process.env.PUBLIC_URL + "/img/profile.jpg"}
                  className="img-fluid img-profile rounded-circle mx-auto mb-2"
                  alt={process.env.REACT_APP_NAME}
                />
              </div>
            </Link>
          </span>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav js-scroll-trigger">
            <li className="nav-item">
              <NavLink
                className="nav-link"
                exact
                activeClassName="active"
                to="/"
              >
                About
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                activeClassName="active"
                to="/articles"
              >
                Articles
              </NavLink>
            </li>
            {/*<li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/experience">Experience</NavLink>
            </li>*/}
            <li className="nav-item">
              <NavLink
                className="nav-link"
                activeClassName="active"
                to="/skills"
              >
                Skills
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                activeClassName="active"
                to="/portfolio"
              >
                Portfolio
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                activeClassName="active"
                to="/services"
              >
                Services
              </NavLink>
            </li>
            {/*<li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/education">Education</NavLink>
            </li>*/}
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/interests">Interests</NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                className="nav-link"
                activeClassName="active"
                to="/contact"
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
 
export default Header;