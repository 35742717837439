import React from "react";
//import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch  } from "react-router-dom";
import createHistory from 'history/createBrowserHistory'
import { CSSTransition } from "react-transition-group";
import MetaTags from 'react-meta-tags';
import ReactGA from 'react-ga';

import Header from './layouts/header';
//import Footer from './layouts/footer';
import './assets/css/custom.scss';

import Routers from "./routes/routes";

const history = createHistory()
ReactGA.initialize(process.env.REACT_APP_GA);
history.listen((location, action) => {
    console.log("LOCATION:: " + location.pathname + location.search);
    ReactGA.pageview(location.pathname + location.search);
});

export default function PageLayout() {
  
  return (
    <Router>
      <MetaTags>
        <title>{process.env.REACT_APP_NAME}</title>
        <meta
          name="description"
          content="{process.env.REACT_APP_NAME} Web Developer"
        />
        <meta property="og:title" content="{process.env.REACT_APP_NAME}" />
        <meta
          property="og:image"
          content="{process.env.PUBLIC_URL + '/img/profile.jpg'}"
        />
      </MetaTags>

      <Header />

      <div className="container-fluid p-0 js-scroll-trigger">
        <hr className="m-0" />

        <Route
          render={({ location }) => (
            <CSSTransition key={location.key} timeout={450} classNames="fade">
              <Routers />
            </CSSTransition>
          )}
        />
      </div>
    </Router>
  );
}

/*
function initializeReactGA() {
    ReactGA.initialize('UA-138256369-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    alert('loadedd');
}


function Topics({ match }) {
  return (
    <div>
      <h2>Topics</h2>
      <ul>
        <li>
          <Link to={`${match.url}/rendering`}>Rendering with React</Link>
        </li>
        <li>
          <Link to={`${match.url}/components`}>Components</Link>
        </li>
        <li>
          <Link to={`${match.url}/props-v-state`}>Props v. State</Link>
        </li>
      </ul>

      <Route path={`${match.path}/:topicId`} component={Topic} />
      <Route
        exact
        path={match.path}
        render={() => <h3>Please select a topic.</h3>}
      />
    </div>
  );
}

function Topic({ match }) {
  return (
    <div>
      <h3>{match.params.topicId}</h3>
    </div>
  );
}

import React, { Component } from 'react';

class App extends Component {
  handleClick = () => {
    import('./moduleA')
      .then(({ moduleA }) => {
        // Use moduleA
        alert('click');
      })
      .catch(err => {
        // Handle failure
      });
  };

  render() {
    return (
      <div>
        <button onClick={this.handleClick} className="btn btn-success">Load</button>
      </div>
    );
  }
}

export default App;*/

/*https://create-react-app.dev/docs/adding-css-reset*/