import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

class PageError extends React.Component {

  render() {
	    return (
	    <section className="resume-section p-3 p-lg-5 d-flex align-items-center">
	      <div className="w-100">
	        {ReactHtmlParser(this.props.message)}
	      </div>
	    </section>
	    )
	              
  }  
}

export default PageError; 