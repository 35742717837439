import React from "react";
import ReactHtmlParser from "react-html-parser";
import MetaTags from "react-meta-tags";

import Fade from "react-reveal/Fade";

import PageTitle from "../components/PageTitle";
import PageLoader from "../components/PageLoader";
import PageError from "../components/PageError";
import helpers from "../utils/iHelpers.js";
import API from "../utils/API";

const PAGE_API = process.env.REACT_APP_PAGE_INTERESTS;

export default class Interests extends React.Component {
  state = {
    title: "Services",
    records: [],
    page: [],
    content: [],
    field: [],
    metatag: [],
    isLoading: false,
    error: null,
    persons: [],
  };

  componentDidMount() {
    helpers.initGA();
    this.setState({ isLoading: true });

    API.get(PAGE_API)
      .then((res) => {
        const data = res.data;
        this.setState({
          page: {
            title: data.title.rendered,
            content: data.content.rendered,
            date: data.date,
          },
          metatag: data.meta_head,
          isLoading: false,
        });
      })
      .catch((error) => this.setState({ error, isLoading: false }));
  }

  render() {
    const { page, metatag, isLoading, error } = this.state;

    if (error) {
      return <PageError message={error.message} />;
    }

    if (isLoading) {
      return <PageLoader />;
    }

    return (
      <section className="resume-section p-3 p-lg-5 d-flex align-items-center">
        <MetaTags>
          <title>{metatag.og_title}</title>
          <meta name="description" content={metatag.og_description} />
          <meta property="og:title" content={metatag.og_title} />
          <meta property="og:description" content={metatag.og_description} />
          <meta property="og:image" content={metatag.og_image} />
          <meta
            property="og:url"
            content={process.env.REACT_APP_URL}
            services
          />
        </MetaTags>
        <div className="w-100">
          <PageTitle title={page.title} />

          <Fade top cascade>
            <div className="content">{ReactHtmlParser(page.content)}</div>
          </Fade>
        </div>
      </section>
    );
  }
}
