import React from 'react';

import PageTitle from '../components/PageTitle';
import PageLoader from '../components/PageLoader';

class Others extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: "Others"
    };
  }

  render() {
    return(
      <section className="resume-section p-3 p-lg-5 d-flex align-items-center" id="interests">
        <div className="w-100">
          <PageTitle title={this.state.title} />
          <p>Coming Soon...</p>

        </div>
      </section>
      );
  }
}
export default Others