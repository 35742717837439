import React from 'react';
/*import ReactDOM from 'react-dom';*/
import MetaTags from 'react-meta-tags';
import ReactHtmlParser from 'react-html-parser';
import Fade from 'react-reveal/Fade'; 

import helpers from "../utils/iHelpers";
import PageLoader from '../components/PageLoader';
import PageError from '../components/PageError';
import API from "../utils/API";
import PageTitle from '../components/PageTitle';
import ContactForm from '../components/ContactForm';


class Contact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: "Contact",
      value: '',
      form_loading: false,
      notification: '',
      isLoading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  //const [client, setClient] = useState({name: '' , email: '', subject: '', message: ''})

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {    
    event.preventDefault();

    const data = new FormData(event.target);
    const POST_API = process.env.REACT_APP_CONTACT_FORM;

    this.setState({form_loading:true});

    API.post(POST_API, { data})    
    .then(res =>{
      const data = res.data;
      this.setState({ form_loading: false, isLoading: false });
      if(data.status === 'mail_sent') {
        const element = '<div class="alert alert-success mt-3" role="alert">'+data.message+'</div>';
        this.setState({ notification: element });
        //ReactDOM.render(ReactHtmlParser(element), document.getElementById('formnotification'));
        document.getElementById("contactForm").reset();
      } else {          
        const element = '<div class="alert alert-danger mt-3" role="alert">'+data.message+'</div>';
        this.setState({ notification: element });
        //ReactDOM.render(ReactHtmlParser(element), document.getElementById('formnotification'));
      }

    })
    .catch(error => this.setState({ error, isLoading: false }));

  }

  render() {
    helpers.initGA();
    
    const {isLoading, error } = this.state;
    if (error) {    
      return <PageError message={error.message}/>;       
    }
    
    if (isLoading) {
      return <PageLoader />;      
    }

    return (

      <section className="resume-section p-3 p-lg-5 d-flex align-items-center" id="interests">
        <MetaTags>
          <title>Contact - {process.env.REACT_APP_NAME}</title>
          <meta name="description" content={'Sent message for more info.'} />
          <meta property="og:description" content={'Sent message for more info.'} />
          <meta property="og:title" content={'Contact Me - '+process.env.REACT_APP_NAME} />
          <meta property="og:image" content={process.env.PUBLIC_URL + '/img/profile.jpg'} />
          <meta property="og:url" content={process.env.PUBLIC_URL + 'contact'} />
        </MetaTags>       
        <div className="w-100">
          <PageTitle title={this.state.title} />
          <div className="well well-sm">
          <Fade top cascade>
            <ContactForm />
          </Fade>
          </div>

        </div>
      </section>
    )
  }
}
export default Contact