import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

import Slide from 'react-reveal/Slide';

class PageTitle extends Component {
  render() {

  	const titleClass = this.props.class ? this.props.class : 'mb-5';

    return (
		<Slide left><h2 className={titleClass}>{ReactHtmlParser(this.props.title)}</h2></Slide>
    )
  }
}
export default PageTitle; 