import React from 'react';
import PageTitle from '../components/PageTitle';
import PageLoader from '../components/PageLoader';

class Notfound extends React.Component {
  render() {
    return (
      <section className="resume-section p-3 p-lg-5 d-flex align-items-center" id="interests">
        <div className="w-100">
          <PageTitle title="Page Not Found" />
          <p></p>
        </div>
      </section>
    )
  }
}
export default Notfound