import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import MetaTags from 'react-meta-tags';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';

import TestimonialForm from '../components/TestimonialForm';

import Popup from "reactjs-popup";

import makeCarousel from 'react-reveal/makeCarousel';
// we'll need the Slide component for sliding animations

// we'll use styled components for this tutorial
// but you can use any other styling options ( like plain old css )
import styled, { css } from 'styled-components';

import PageTitle from '../components/PageTitle';
import PageLoader from '../components/PageLoader';
import PageError from '../components/PageError';
import helpers from "../utils/iHelpers.js";
import API from "../utils/API";

const PAGE_API      = process.env.REACT_APP_PAGE_ABOUT;
const API_URL_TESTI = process.env.REACT_APP_PAGE_TESTI;

class About extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: "About",
      field: [],
      metatag: [],
      testimonials: [],
      isLoading: false,
      error: null,
    };
  }

  componentDidMount() {

    helpers.initGA();
    this.setState({ isLoading: true });

    API.get(PAGE_API)    
      .then(res => {
          const data = res.data;
          this.setState({ field: data.metas, metatag: data.meta_head, isLoading: false });
      })
      .catch(error => this.setState({ error, isLoading: false }));
    
    API.get(API_URL_TESTI)    
      .then(res => {
          const data = res.data;
          this.setState({ testimonials: data });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {    

    const { field, metatag, testimonials, isLoading, error } = this.state;
    if (error) {    
      return <PageError message={error.message}/>;    
    }
    
    if (isLoading) {
      return <PageLoader />;      
    }

    const width = '100%', height='200px';
    const Container = styled.div`
      position: relative;
      overflow: hidden;
      width: ${width};
      height: ${height};
    `;
    const Arrow = styled.div`
      text-shadow: 1px 1px 1px #fff;
      z-index: 100;
      line-height: ${height};
      text-align: center;
      position: absolute;
      top: 0;
      width: 10%;
      font-size: 3em;
      cursor: pointer;
      user-select: none;
      ${props => props.right ? css`left: 90%;` : css`left: 0%;`}
    `;
    const CarouselUI = ({ position, handleClick, children }) => (
      <Container>
          {children}
          <Arrow className="arrow-left" onClick={handleClick} data-position={position - 1}><i className="fas fa-angle-left"></i></Arrow>
          <Arrow className="arrow-right" right onClick={handleClick} data-position={position + 1}><i className="fas fa-angle-right"></i></Arrow>
      </Container>
    );
    const Carousel = makeCarousel(CarouselUI);

    return (
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="about"
      >
        <MetaTags>
          <title>{metatag.og_title}</title>
          <meta name="description" content={metatag.og_description} />
          <meta property="og:title" content={metatag.og_title} />
          <meta property="og:description" content={metatag.og_description} />
          <meta property="og:image" content={metatag.og_image} />
          <meta property="og:url" content={process.env.REACT_APP_URL} />
        </MetaTags>
        <div className="w-100">
          <Fade right>
            <h1 className="mb-0">
              Fritz Darryl
              <span className="text-primary">Roca</span>
            </h1>
          </Fade>
          <Fade top>
            <div className="subheading mb-5">
              {field.address} · {field.contact} ·
              <a href="mailto:{field.email_address}">{field.email_address}</a>
            </div>
            <p className="lead mb-5">{field.about_me}</p>
          </Fade>
          <Fade top>
            <div className="social-icons">
              <a
                href={field.linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a href={field.github} target="_blank" rel="noopener noreferrer">
                <i className="fab fa-github"></i>
              </a>
              <a href={field.twitter} target="_blank" rel="noopener noreferrer">
                <i className="fab fa-twitter"></i>
              </a>
            </div>
          </Fade>

          <div className="mt-5 section-slider" id="section-testimonials">
            <header className="App-header">
              <PageTitle title="Testimonials" />
            </header>

            <Slide top>
              <div className="row mb-5">
                <Carousel defaultWait={10000}>
                  {testimonials.map((record, index) => (
                    <Slide right>
                      <div className="col-sm-12">
                        <div className="testimonial-wrapper mx-4">
                          <div className="testimonial">
                            {ReactHtmlParser(record.meta_acf.content)} ---{" "}
                            <i>{record.meta_acf.website}</i>
                          </div>
                          <div className="media">
                            {/*<div className="media-left d-flex mr-3">
                        <img src="/img/dummy.jpg" />                   
                      </div>*/}
                            <div className="media-body">
                              <div className="overview">
                                <div className="name">
                                  <b>{record.meta_acf.full_name}</b>
                                </div>
                                <div className="details">
                                  {record.meta_acf.company_name}
                                </div>
                                <div className="star-rating">
                                  <ul className="list-inline rate-{record.meta_acf.rate}">
                                    {(function () {
                                      let rate = "";
                                      for (
                                        let i = 0;
                                        i < record.meta_acf.rate;
                                        i++
                                      ) {
                                        rate =
                                          rate +
                                          '<li class="list-inline-item rate-' +
                                          i +
                                          '"><i class="fa fa-star"></i></li>';
                                      }
                                      return ReactHtmlParser(rate);
                                    })()}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Slide>
                  ))}
                </Carousel>
              </div>
            </Slide>
            <Popup
              position="top center"
              closeOnDocumentClick
              trigger={
                <button className="btn btn-primary btn-success">
                  {" "}
                  Post Feedback
                </button>
              }
              modal
              className="btn btn-primary btn btn-danger"
            >
              {(close) => (
                <div className="modal2">
                  <a
                    className="close"
                    onClick={close}
                    href="/#"
                    rel="noopener noreferrer"
                  >
                    &times;
                  </a>
                  <TestimonialForm />
                </div>
              )}
            </Popup>
            
          </div>
        </div>
      </section>
    );
  }
}
export default About