//import helpers from '../iHelpers.js';
import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';


class TestimonialForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      form_loading: false,
      notification: '',
      isLoading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {    
    event.preventDefault();

    const data = new FormData(event.target);

    var url = process.env.REACT_APP_BASE_API + process.env.REACT_APP_TESTI_FORM;

    //this.setState({ isLoading: true });
    this.setState({form_loading:true});

    fetch(url, {
      method: 'POST',
      body: data,
    })
    .then(response => {
      return response.json();
    })

    .then((data) =>{
      this.setState({ form_loading: false, isLoading: false });
      if(data.status === 'success') {
        const element = '<div class="alert alert-success mt-3" role="alert">'+data.message+'</div>';
        this.setState({ notification: element });
        document.getElementById("TestimonialForm").reset();
      } else {
        const element = '<div class="alert alert-danger mt-3" role="alert">'+data.message+'</div>';
        this.setState({ notification: element });  
      }

    })
    .catch(error => this.setState({ error, isLoading: false }));

  }

	render() {
		const {form_loading, notification} = this.state;

		return (
      <div className="well well-sm text-left  pr-3 pl-3 pb-3">
        <h2>New Feedback</h2>
        <form
          onSubmit={this.handleSubmit}
          id="TestimonialForm"
          className="form-label-floating"
        >
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="full_name"
                  required="required"
                  name="full_name"
                />
                <label className="form-control-placeholder" for="full_name">
                  Full Name
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  required="required"
                  name="email"
                />
                <label className="form-control-placeholder" for="email">
                  Email Address
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="company_name"
                  required="required"
                  name="company_name"
                />
                <label className="form-control-placeholder" for="company_name">
                  Company Name
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="website"
                  required="required"
                  name="website"
                />
                <label className="form-control-placeholder" for="website">
                  Company Website
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <textarea
                  id="content"
                  className="form-control"
                  rows="9"
                  cols="25"
                  required="required"
                  name="content"
                ></textarea>
                <label className="form-control-placeholder" for="content">
                  Message
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <select
                  id="rate"
                  className="form-control"
                  required="required"
                  name="rate"
                >
                  <option value="">Rate</option>
                  <option value="1">1 Star</option>
                  <option value="2">2 Star</option>
                  <option value="3">2 Star</option>
                  <option value="4">4 Star</option>
                  <option value="5" selected="selected">
                    5 Star
                  </option>
                </select>
                {/*<label className="form-control-placeholder" for="rate">Rate</label>*/}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-left">
              {form_loading
                ? ReactHtmlParser(
                    '<button type="submit" class="btn btn-primary btn btn-danger pull-right disabled" id="btnContactUs"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="ml-4">Loading...</span></button>'
                  )
                : ReactHtmlParser(
                    '<button type="submit" class="btn btn-primary btn btn-danger pull-right" id="btnContactUs">Send Message</button>'
                  )}
            </div>
          </div>
          {notification
            ? ReactHtmlParser(
                '<div id="formnotification">' + notification + "</div>"
              )
            : ""}
        </form>
      </div>
    );
	}
}


export default TestimonialForm;