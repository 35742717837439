import React from 'react';
import SkillItem from '../components/SkillItem';

class SkillSet extends React.Component {
  /*constructor() {
    super();
  }*/
  
  skills(data, parent) {
    const children = (skills) => {
      if (skills) {
        return (
              <ul className="list-inline dev-icons">{ this.skills(skills, false) }
              </ul>
          );
      }
    }
    
    return data.map((node, index) => {
      return (
        <SkillItem
          key={node.id}
          parent={parent}
          name={node.label}
          icon={node.skill_icon}
          rate={node.rate}
          skill={node.skill}
        >
          {children(node.skills)}
        </SkillItem>
      );
    })
  }
  
  render() {
    return <div className={this.props.className}>
        { this.skills(this.props.data, true) }
    </div>
  }
}

export default SkillSet; 