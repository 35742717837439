import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link, NavLink  } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import PageTitle from '../components/PageTitle';

import Moment from 'react-moment';
import Reveal from 'react-reveal/Reveal';
import Fade from 'react-reveal/Fade'; 


import helpers from "../utils/iHelpers.js";
import PageLoader from '../components/PageLoader';

//const API_SRC = "https://syntaxified.local";
const API_SRC = process.env.REACT_APP_BLOG_BASE_API;
const API_URL = API_SRC + "/wp-json/wp/v2/posts/?slug=";

class Article extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: "Article",
      records: [],
      page: [],
      categories: [],
      field: [],
      metatag: [],        
      isLoading: false,
      error: null,
    };
  }

  componentDidMount() {
    helpers.initGA();
    
    this.setState({ isLoading: true });
    fetch(API_URL+this.props.match.params.slug)    
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .then(data => this.setState({ 
        page : {
          title: data[0].title.rendered, 
          content: data[0].content.rendered,           
          image_url: data[0].featured_image_url,           
          date: data[0].date,   
          slug: data[0].slug        
        },
        categories: data[0].categories,           
        metatag: data[0].meta_head, 
        isLoading: false }))
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {
    const { page, categories, title, metatag, isLoading, error } = this.state;
    if (error) {    
      return ReactHtmlParser(helpers.pageError(error.message));      
    }
    
    if (isLoading) {
      return <PageLoader />;      
    }
    
    /*if (!records) {return null;}*/

    return (

      <section className="resume-section p-3 p-lg-5 d-flex align-items-center">
        <MetaTags>
          <title>{metatag.og_title}</title>
          <meta name="description" content={metatag.og_description} />
          <meta property="og:title" content={metatag.og_title} />
          <meta property="og:description" content={metatag.og_description} />
          <meta property="og:image" content={metatag.og_image} />
          <meta property="og:url" content={ReactHtmlParser(process.env.REACT_APP_URL+'article/'+page.slug)} />
        </MetaTags>
        <div className="w-100">

          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><NavLink to="/articles">Articles</NavLink></li>
              <li class="breadcrumb-item active" aria-current="page">{ReactHtmlParser(page.title)}</li>
            </ol>
          </nav>

          <PageTitle title={page.title} class='mb-0' />
          <Fade top>
          <p class="post-meta post-categories text-primary">Category: {categories.map((record, index) =>
              <Link to={`/articles/${record.slug}`} className="mr-1 badge-info badge" id={record.ID}>{record.name}</Link>       
            )} | Posted on <Moment format="MMM DD, YYYY">{page.date}</Moment></p>
          </Fade>
          <Fade top cascade>
          <div className="page-banner mt-4">
          { page.image_url ? ReactHtmlParser('<img src="'+page.image_url+'" data-holder-rendered="true" class="img-fluid" />') : ReactHtmlParser('<div class="page-no-banner mb-3"><img src="/img/default.png" data-holder-rendered="true" /></div>') }
          </div>
          </Fade>
          <Fade top cascade>
            <div className="content mt-4">
              {ReactHtmlParser(page.content)}
            </div>
          </Fade>


          <Reveal effect="fadeInUp"></Reveal>

        </div>
      </section>
    );

  }

}

export default Article;
