import React from 'react';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';

class Item extends React.Component {
  render() {

    //let itemrow;

    if (this.props.parent) {
      return <div className="mb-4"><div className="subheading mb-3">{ this.props.name }</div>{this.props.children}</div> ;
    } else {
      return <Bounce right><li><i class="fa-li fa fa-check"></i>{ this.props.description } </li></Bounce>;
    }

  }
}

class List extends React.Component {
  /*constructor() {
    super();
  }*/
  
  list(data, parent) {
    const children = (role) => {
      if (role) {
        return (
              <ul className="fa-ul mb-0">
              { this.list(role, false) }
              </ul>
          );
      }
    }
    
    return data.map((node, index) => {
      return (
        <Item key={ node.id } parent={parent} name={ node.name } description={ node.description }>
          { children(node.role) }
        </Item>
      );

    })
  }
  
  render() {
    return (
      <Fade top cascade>
        <div className={this.props.className}>
          { this.list(this.props.data, true) }
        </div>
      </Fade>
    )
  }
}

export default List; 