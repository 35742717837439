//import helpers from '../iHelpers.js';
import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Fade from 'react-reveal/Fade'; 

class ContactForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      form_loading: false,
      notification: '',
      isLoading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {    
    event.preventDefault();

    const data = new FormData(event.target);

    var url =
      process.env.REACT_APP_BASE_API + process.env.REACT_APP_CONTACT_FORM;

    //this.setState({ isLoading: true });
    this.setState({form_loading:true});
    //console.log(url);
    //console.log(data);
    fetch(url, {
      method: 'POST',
      body: data,
    })
    .then(response => {
      return response.json();
    })

    .then((data) =>{
      this.setState({ form_loading: false, isLoading: false });
      if(data.status === 'mail_sent') {
        const element = '<div class="alert alert-success mt-3" role="alert">'+data.message+'</div>';
        this.setState({ notification: element });
        document.getElementById("contactForm").reset();
      } else {
        const element = '<div class="alert alert-danger mt-3" role="alert">'+data.message+'</div>';
        this.setState({ notification: element });  
      }

    })
    .catch(error => this.setState({ error, isLoading: false }));

  }

	render() {
		const {form_loading, notification} = this.state;

		return (
            <form onSubmit={this.handleSubmit} id="contactForm" className="form-label-floating">
            <input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f49-2024" />            
            <Fade top cascade>          
              <div className="row">
                  <div className="col-md-6">

                      <div className="form-group">
                          <input type="text" className="form-control" id="name" required="required" name="client-name" />
                          <label className="form-control-placeholder" for="name">
                              Name</label>
                      </div>
                      <div className="form-group">
                          <input type="email" className="form-control" id="email" required="required" name="client-email" />
                          <label className="form-control-placeholder" for="email">Email Address</label>
                      </div>
                      <div className="form-group">
                          <select id="subject" className="form-control" required="required"  name="client-subject">
                              <option value="na" selected="">Select Subject</option>
                              <option value="service">General Customer Service</option>
                              <option value="suggestions">Suggestions</option>
                              <option value="product">Product Support</option>
                          </select>
                          {/*<label className="form-control-placeholder" for="subject">Subject</label>*/}
                      </div>
                  </div>
                  <div className="col-md-6">
                      <div className="form-group">
                          <textarea id="message" className="form-control" rows="9" cols="25" required="required" name="client-message"></textarea>
                          <label className="form-control-placeholder" for="name">Message</label>
                      </div>
                  </div>
                  <div className="col-md-12">
                      { form_loading ?
                        ReactHtmlParser('<button type="submit" class="btn btn-primary btn btn-danger pull-right disabled" id="btnContactUs"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="ml-4">Loading...</span></button>')
                          :
                        ReactHtmlParser('<button type="submit" class="btn btn-primary btn btn-danger pull-right" id="btnContactUs">Send Message</button>') 
                      }
                  </div>
              </div>
              {notification ?
                ReactHtmlParser('<div id="formnotification">'+notification+'</div>')
                : ''
              }           
            </Fade>       
            </form>

		    );
	}
}


export default ContactForm;