import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Slide from 'react-reveal/Slide';

class SkillItem extends React.Component {
  render() {

    if (this.props.parent) {
      return <div className="mb-4"><Slide left><div className="subheading mb-3">{ this.props.name }</div></Slide >{this.props.children}</div> ;
    } else {
      let rate = "Rate " + this.props.rate;
      return (
        <Slide right>
          <li className="list-inline-item" title={rate}>
            {ReactHtmlParser(this.props.icon)}{" "}            
          </li>
        </Slide>
      );
    }

  }
}
export default SkillItem; 