import React from "react";
import ReactHtmlParser from "react-html-parser";
import { NavLink, Route, Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import PageTitle from "../components/PageTitle";

import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";

import helpers from "../utils/iHelpers.js";
import PageLoader from "../components/PageLoader";
import PageError from "../components/PageError";
import API from "../utils/API";

const PAGE_API = process.env.REACT_APP_PAGE_PORTFOLIO;
const POST_CAT_API = process.env.REACT_APP_ARTICLE;

function Single({ match }) {
  return (
    <div>
      <h3>ID: {match.params.id}</h3>
    </div>
  );
} /*https://dev.to/amanhimself/using-react-router-to-optimize-single-page-applications-4mim*/

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Portfolio",
      records: [],
      category: false,
      field: [],
      metatag: ["og_description", "og_title", "og_description"],
      isLoading: false,
      error: null,
    };
  }

  componentDidMount() {
    helpers.initGA();

    this.setState({ isLoading: true });

    const category = this.props.match.params.slug
      ? this.props.match.params.slug
      : "";
    const API_URLs = category ? POST_CAT_API + category + "/?_embed" : PAGE_API;

    API.get(API_URLs)
      .then((res) => {
        const data = res.data;
        this.setState({
          records: category ? data.records : data,
          category: category ? data.category : false,
          isLoading: false,
        });
      })
      .catch((error) => this.setState({ error, isLoading: false }));
  }

  render() {
    const { records, category, metatag, isLoading, error } = this.state;
    if (error) {
      return <PageError message={error.message} />;
    }

    if (isLoading) {
      return <PageLoader />;
    }

    const current_category = category
      ? ReactHtmlParser(
          '<li class="breadcrumb-item active text-capitalize" aria-current="page">' +
            category.name +
            "</li>"
        )
      : "";
    const og_url = category
      ? ReactHtmlParser(process.env.REACT_APP_URL + "articles/" + category.slug)
      : ReactHtmlParser(process.env.REACT_APP_URL + "articles/");

    /*if (!records) {return null;}*/

    return (
      <section className="resume-section p-3 p-lg-5 d-flex align-items-center">
        <MetaTags>
          <title>{metatag.og_title}</title>
          <meta name="description" content={metatag.og_description} />
          <meta property="og:title" content={metatag.og_title} />
          <meta property="og:description" content={metatag.og_description} />
          <meta property="og:image" content={metatag.og_image} />
          <meta property="og:url" content={og_url} />
        </MetaTags>
        <div className="w-100">
          <nav
            aria-label="breadcrumb"
            className={current_category ? "" : "d-none"}
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <NavLink to="/articles">Articles</NavLink>
              </li>
              {current_category}
            </ol>
          </nav>
          <PageTitle
            title={current_category ? category.name : this.state.title}
          />
          <div className="row">
            <div className="card-rows">
              {records.map((record, index) => (
                <div class="card overflow-hidden shadow rounded-4 border-0 mb-5">
                  <Fade top>
                    <div class="card-body p-0">
                      <div class="d-md-flex align-items-center">
                        <div class="p-5 col-md-6">
                          <div class="post-meta text-right">
                            <a
                              href={ReactHtmlParser(record.acf.demo)}
                              id="{record.id}"
                              target="_blank"
                            >
                              {ReactHtmlParser(record.acf.demo)}
                            </a>
                          </div>
                          <h2 class="fw-bolder">
                            {ReactHtmlParser(record.title.rendered)}
                          </h2>
                          <div className="card-text">
                            {ReactHtmlParser(record.content.rendered)}
                          </div>
                          <p class="post-meta post-categories text-primary">
                            Skills:{" "}
                            <span className="mr-1 badge-success badge p-2">
                              {ReactHtmlParser(record.acf.technologies)}
                            </span>
                          </p>
                        </div>
                        <div class="">
                          {record.preview
                            ? ReactHtmlParser(
                                '<img class="img-fluid" src="' +
                                  record.preview +
                                  '" data-holder-rendered="true" />'
                              )
                            : ReactHtmlParser(
                                '<img class="img-fluid" src="/img/default.png" data-holder-rendered="true" />'
                              )}
                          {record.preview_mobile
                            ? ReactHtmlParser(
                                '<img class="img-preview-mobile" src="' +
                                  record.preview_mobile +
                                  '" data-holder-rendered="true" />'
                              )
                            : ""}
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div>
              ))}
              <div className="col-md-4 col-sm-6"></div>
              <Route path="/posts/:id" component={Single} />
              <Slide cascade right="1"></Slide>
            </div>
          </div>
        </div>
      </section>
    );
    /*{process.env.REACT_APP_URL}*/
  }
}

export default Portfolio;
