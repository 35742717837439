import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { NavLink, Route, Link  } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import PageTitle from '../components/PageTitle';

import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade'; 

import helpers from "../utils/iHelpers.js";
import PageLoader from '../components/PageLoader';
import PageError from '../components/PageError';
import API from "../utils/APIBlog";

const PAGE_API        = process.env.REACT_APP_PAGE_ARTICLE;
const POST_CAT_API    = process.env.REACT_APP_ARTICLE;

function Single({ match }) {
    return (
        <div>
            <h3>ID: {match.params.id}</h3>
        </div>
    )
} /*https://dev.to/amanhimself/using-react-router-to-optimize-single-page-applications-4mim*/

class Blog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: "Articles",
      records: [],
      category: false,
      field: [],
      metatag: ['og_description', 'og_title', 'og_description'],        
      isLoading: false,
      error: null,
    };
  }

  componentDidMount() {
    helpers.initGA();
    
    this.setState({ isLoading: true });

    const category = this.props.match.params.slug ? this.props.match.params.slug : '';
    const API_URLs = category ? POST_CAT_API+category+'/?_embed' : PAGE_API;

    API.get(API_URLs)
      .then(res => {
          const data = res.data;
          this.setState({ records: category? data.records :  data, category: category? data.category : false, isLoading: false })
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  render() {

    const { records,category, metatag, isLoading, error } = this.state;
    if (error) {    
      return <PageError message={error.message}/>;      
    }

    if (isLoading) {
      return <PageLoader />;      
    }
    
    const current_category = category ? ReactHtmlParser('<li class="breadcrumb-item active text-capitalize" aria-current="page">'+category.name+'</li>') : '';
    const og_url = category ? ReactHtmlParser(process.env.REACT_APP_URL+'articles/'+category.slug) : ReactHtmlParser(process.env.REACT_APP_URL+'articles/');

    /*if (!records) {return null;}*/

    return (
      <section className="resume-section p-3 p-lg-5 d-flex align-items-center">
        <MetaTags>
          <title>{metatag.og_title}</title>
          <meta name="description" content={metatag.og_description} />
          <meta property="og:title" content={metatag.og_title} />
          <meta property="og:description" content={metatag.og_description} />
          <meta property="og:image" content={metatag.og_image} />
          <meta property="og:url" content={og_url} />
        </MetaTags>
        <div className="w-100">
          <nav
            aria-label="breadcrumb"
            className={current_category ? "" : "d-none"}
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <NavLink to="/articles">Articles</NavLink>
              </li>
              {current_category}
            </ol>
          </nav>
          <PageTitle
            title={current_category ? category.name : this.state.title}
          />
          <div className="row">
            <div className="card-columns">
              {records.map((record, index) => (
                <div className="card mb-4 box-shadow">
                  <div className="m-3">
                    {record.featured_image_url
                      ? ReactHtmlParser(
                          '<img class="card-img-top" src="' +
                            record.featured_image_url +
                            '" data-holder-rendered="true" />'
                        )
                      : ReactHtmlParser(
                          '<img class="card-img-top" src="/img/default.png" data-holder-rendered="true" />'
                        )}
                  </div>
                  <div className="card-body">
                    <Fade top>
                      <h3 className="mb-0">
                        <Link
                          to={`/article/${record.slug}`}
                          id={record.id}
                        >
                          {ReactHtmlParser(record.title.rendered)}
                        </Link>
                      </h3>
                      <div className="card-text">
                        {ReactHtmlParser(record.excerpt.rendered)}
                      </div>
                    </Fade>
                    <Fade bottom>
                      <div className="d-flex justify-content-between align-items-center">
                        <Link
                          to={`/article/${record.slug}`}
                          className="btn btn-sm btn-outline-secondary"
                          id={record.id}
                        >
                          Read More
                        </Link>
                      </div>
                    </Fade>
                  </div>
                </div>
              ))}
              <div className="col-md-4 col-sm-6"></div>
              <Route path="/posts/:id" component={Single} />
              <Slide cascade right="1"></Slide>
            </div>
          </div>
        </div>
      </section>
    );
    /*{process.env.REACT_APP_URL}*/
  }

}

export default Blog;
